import React, { Component } from "react"
import pageStyles from './page.module.css'

class Page extends Component {
  postTitle(title) {
    return <span dangerouslySetInnerHTML={{ __html: title}} />
  }
  postContent(content) {
    return <div dangerouslySetInnerHTML={{ __html: content }} />
  }

  render() {

    return (
      <>
        <div className="m-6">
          <div className="flex mb-6">
            <div className="w-full md:w-7/12 lg:w-7/12 xl:w-7/12">
              <div className={`text-3xl md:text-4xl lg:text-4xl xl:text-4xl uppercase p-4 border border-gray-400 hover:bg-k7green block cursor pointer`}>
                {this.postContent( this.props.title )}
              </div>
              <div 
                className={`${pageStyles.page} content block p-6 border border-gray-400 border-t-0 text-sm`}
              >
                {this.postContent( this.props.content )}
              </div>
            </div>
          </div>
        </div>
      </>
    )

  }



}

export default Page
